import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../util/constants";
import { deleteSubject } from "../api/subjects/request";
import { useState } from "react";

const style = {
  titleText: {
    fontSize: "22px",
    fontWeight: "bold",
  },
};

interface ModelPanelDeleteSubjectProps {
  idSubject: number;
  closeModel: () => void;
}

export const ModelPanelDeleteSubject: React.FC<ModelPanelDeleteSubjectProps> = (
  props
) => {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);

  const handleDelete = () => {
    deleteSubject(props.idSubject)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Delete subjects faild!");
        } else {
          setError(false);
          navigate(routes.main);
        }
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      });
  };

  return (
    <Box p={5} sx={{ backgroundColor: "white" }} textAlign={"center"}>
      <Typography style={style.titleText}>
        Вы точно хотите удалить данные испытуемого?
      </Typography>

      <Typography>Вся информация и результаты будут удалены!</Typography>

      <Stack direction={"row"} spacing={2} justifyContent={"center"} mt={2}>
        <Button variant="outlined" onClick={props.closeModel}>
          Отмена
        </Button>

        <Button variant="contained" onClick={handleDelete}>
          Удалить
        </Button>
      </Stack>
      {error && (
        <Typography mt={2} color={"tomato"}>
          Произошла ошибка удаления, попробуйте повторить позднее!
        </Typography>
      )}
    </Box>
  );
};
