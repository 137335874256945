import { Routes, Route } from "react-router-dom"
import { routes } from "../util/constants"
import { LoginPage } from "../pages/LoginPage"
import { MainPage } from "../pages/MainPage"
import { SubjectPage } from "../pages/SubjectPage"

export const useRoutes = () => {

    return (
        <Routes>
            <Route path={routes.main} element={<MainPage />} />
            <Route path={routes.login} element={<LoginPage />} />
            <Route path={routes.subjectPage} element={<SubjectPage />} />
        </Routes>
    );
}