import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#FF5443',
        },
        secondary: {
            main: '#FF8C3D'
        },
        background:{
            paper: '#white'
        }
    }
})