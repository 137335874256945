import { BrowserRouter as Router } from "react-router-dom";
import { useRoutes } from "./hooks/useRoutes";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./util/theme";

function App() {
  const routes: JSX.Element = useRoutes();

  return (
    <ThemeProvider theme={theme}>
      <Router>{routes}</Router>
    </ThemeProvider>
  );
}

export default App;