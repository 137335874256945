import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";

const style = {
  titleText: {
    fontSize: "22px",
    fontWeight: "bold",
  },
};

interface ModelPanelEditSpecialistComments {
  closeModal: () => void;
}

export const ModelPanelEditSpecialistComments: FC<
  ModelPanelEditSpecialistComments
> = (props) => {
  return (
    <Box p={5} sx={{ backgroundColor: "white" }} textAlign={"center"}>
      <Stack direction={"row"} spacing={3}>
        <Typography style={style.titleText}>
          Комментарий и оценка специалиста
        </Typography>
        <Button variant="outlined" onClick={props.closeModal}>
          <CloseIcon />
        </Button>
      </Stack>

      <Stack direction={'column'} spacing={2} my={2}>
        <TextField 
          variant="outlined"
          label={"Комментарий специалиста"}
          name={"comment"}
        />

        <TextField
          variant="outlined"
          label={"Оценка врача"}
          name={"assesment"}
          helperText={"Введите оценку от 1 до ?"}
          type="number"
        />
      </Stack>

      <Button 
        variant="contained"
        size="large"
        onClick={() => console.log("СОХРАНЕНИЕ!")}
      >
        Сохранить
      </Button>
    </Box>
  );
};
