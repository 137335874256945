import { Container, Grid, Stack } from "@mui/material";
import { LoginForm } from "../components/LoginForm";
import titleLogo from "../assets/logo.svg";

const styles = {
  titleLogo: {
    height: "80%",
    width: "80%",
  },
};

export const LoginPage = (): JSX.Element => {
    return <Grid sx={{backgroundColor: 'white'}} container direction={'row'} alignItems={'center'} height={'100vh'} spacing={3}>
    <Grid item xs/>
    <Grid item xs={4}>
        <Stack sx={{backgroundColor: 'white', borderRadius: 5, alignItems: 'center'}} direction={'column'}>
            <img src={titleLogo} style={styles.titleLogo} alt={'titleLogoRu'}/>
                    <Container>
      <LoginForm />
    </Container>
        </Stack>
    </Grid>
    <Grid item xs />
</Grid>;
};