import React, { useState } from "react";
import { LoginDto } from "../api/users/dto";
import { useNavigate } from "react-router-dom";
import { auth } from "../api/users/request";
import { OvalTextField } from "../components/styles/OvalTextField";
import { InputAdornment, IconButton, Box } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { WarningParagraph } from "../components/styles/WarningParagraph";
import { OvalPrimaryButton } from "../components/styles/OvalPrimaryButton";

export const LoginForm = () => {
  const [form, setForm] = useState<LoginDto>({ login: "", password: "" });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authError, setAuthError] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
    setAuthError(false);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (form.login.length === 0 || form.password.length === 0) {
      setAuthError(true);
    }

    const response = await auth(form);

    if (response.ok) {
      navigate("/");
    } else {
      console.error(response.status, response.statusText);
      setAuthError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <OvalTextField
        variant="filled"
        fullWidth
        onChange={handleChange}
        label="Логин"
        name="login"
        value={form.login}
        margin="normal"
      />

      <OvalTextField 
        variant="filled"
        fullWidth
        onChange={handleChange}
        name="password"
        label="Пароль"
        type={showPassword ? 'text' : 'password'}
        value={form.password}
        margin="normal"
        InputProps={{
            endAdornment:
            <InputAdornment position="end">
                <IconButton
                    style={{ paddingRight: '1rem' }}
                    onClick={() => setShowPassword(prevState => !prevState)}
                    edge="end"
                >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
            </InputAdornment>
        }}
      />
      {authError && <WarningParagraph variant={'subtitle2'}>Неверный логин или пароль</WarningParagraph>}

      <Box mt={1}>
        <OvalPrimaryButton
            type='submit'
            fullWidth
            variant="contained"
            color='primary'
            style={{ borderRadius: 12 }}
        >
            ВОЙТИ
        </OvalPrimaryButton>
      </Box>
    </form>
  );
};
