import { AppBar, Toolbar, Stack, Button } from "@mui/material";
import {routes} from "../util/constants";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";
import { theme } from "../util/theme";
import { logout } from "../api/users/request";
import logo from "../assets/logo.svg";


export const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const logout = useAuth();

    const exitForUser = async () => {
        const response = await logout();

        if (response.ok) {
            navigate(routes.login);
        } else {
            console.error(response.status, response.statusText);
        }
    };

    const styles = {
        titleLogo: {
            height: '60px',
            width: '200px'
        },
        navbar: {
            background: 'white',
            padding: '8px 30px',
            borderRadius: '12px'
        },
        button: {
            color: theme.palette.primary.main,
            font: 'Nunito Sans',
            weight: '700',
            fontSize: '16px',
            lineHeight: '23.2px'
        },
        buttonActive: {
            color: theme.palette.secondary.main,
            font: 'Nunito Sans',
            weight: '700',
            fontSize: '16px',
            lineHeight: '23.2px'
        },
        buttonExit: {
            color: '#C62336',
            font: 'Nunito Sans',
            weight: '700',
            fonstSize: '16px',
            lineHeight: '23.2px'
        }
    };

    return (
        <AppBar position="static" style={styles.navbar}>
            <Toolbar>
                <Stack direction='row' spacing={4}
                divider={<Divider orientation="vertical" flexItem />}>
                    <Stack>
                        <img alt="logo" src={logo} style={styles.titleLogo}/>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                        <Button href={routes.main} style={location.pathname === routes.main ? styles.buttonActive : styles.button}>Список Испытуемых</Button>
                    </Stack>

                    <Stack direction='row'>
                        <Button href={routes.login} onClick={exitForUser} style={styles.buttonExit}>Выйти</Button>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};