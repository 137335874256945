import { CreateSubject, UpdateSubjectDto } from "./dto";
import { BASE_URL } from "../../util/constants";

export const fetchAllSubjects  = async (): Promise<Response> => {
    return await fetch(`${BASE_URL}/subjects/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        credentials: 'same-origin'
    }
    );
};

export const fetchSubject  = async (id: number): Promise<Response> => {
    return await fetch(`${BASE_URL}/subjects/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        credentials: 'include'
    }
    );
};

export const createNewSubject = async (data: CreateSubject): Promise<Response> => {
    return await fetch(`${BASE_URL}/subjects/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        credentials: 'include',
        body: JSON.stringify({
            name: data.name,
            age: data.age,
            gender: data.gender,
            city: data.city,
            userId: data.userId,
            isFilled: false
        })
    });
};

export const updateSubject = async (data: UpdateSubjectDto, id: number): Promise<Response> => {
    return await fetch(`${BASE_URL}/subjects/${id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json;charsret=UTF-8'
        },
        credentials: 'include',
        body: JSON.stringify({
          name: data.name,
          age: data.age,
          gender: data.gender,
          city: data.city  
        })
    })
}

export const deleteSubject = async (id: number): Promise<Response> => {
    return await fetch(`${BASE_URL}/subjects/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        credentials: 'include',
    })
}