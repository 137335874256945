import { LoginDto } from "./dto";
import { BASE_URL } from "../../util/constants";

export const auth  = async (form: LoginDto): Promise<Response> => {
    return await fetch(`${BASE_URL}/users/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        credentials: 'include',
        body: JSON.stringify({
            login: form.login,
            password: form.password
        })
        }
    );
}

export const logout = async (): Promise<Response> => {
    return await fetch(`${BASE_URL}/users/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        credentials: 'include'
    });
}