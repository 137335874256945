import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SubjectDto, UpdateSubjectDto } from "../api/subjects/dto";
import { updateSubject } from "../api/subjects/request";

const style = {
  titleText: {
    fontSize: "22px",
    fontWeight: "bold",
  },
};

interface ModelPanelEditSubjectInfoProps {
  subject: SubjectDto | undefined,
  closeModal: () => void,
  idSubject: number,
  update: React.Dispatch<React.SetStateAction<object>>
}

export const ModelPanelEditSubjectInfo: React.FC<
  ModelPanelEditSubjectInfoProps
> = (props) => {
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [form, setForm] = useState<UpdateSubjectDto>(
    props.subject !== undefined
      ? props.subject
      : {
          name: undefined,
          age: undefined,
          gender: undefined,
          city: undefined,
        }
  );

  const genderValue = [
    {
      value: null,
      label: "",
    },
    {
      value: 0,
      label: "Женский",
    },
    {
      value: 1,
      label: "Мужской",
    },
  ];

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.currentTarget.name]: event.currentTarget.value });
  };

  const handleSaveSubjectInfo = () => {
    const updateSubjectInfo: UpdateSubjectDto = {
      name: form.name === "" ? null : form.name,
      age: form.age?.toString() === "" ? null : form.age === null ? null : Number(form.age),
      gender: form.gender?.toString() === "" ? null : form.gender === null ? null : Number(form.gender),
      city: form.city === "" ? null : form.city,
    };

    updateSubject(updateSubjectInfo, props.idSubject)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Update faild. ${response.status} ${response.statusText}`
          );
        }
        setError(false);
        setErrorMessage("");
        props.update({});
        props.closeModal();
      })
      .catch((error) => {
        setError(true);
        setErrorMessage("Проверьте введеные данные и попробуйте еще раз!");
        console.error(error);
      });
  };

  return (
    <Box p={5} sx={{ backgroundColor: "white" }} textAlign={"center"}>
      <Stack direction={"row"} spacing={3}>
        <Typography style={style.titleText}>Информация испытуемого</Typography>
        <Button variant="outlined" onClick={props.closeModal}>
          <CloseIcon />
        </Button>
      </Stack>

      <Stack direction={"column"} spacing={2} my={2}>
        <TextField
          variant="outlined"
          label={"Имя"}
          name={"name"}
          value={form.name}
          onChange={handleNameChange}
        />
        <TextField
          variant="outlined"
          label={"Возраст"}
          name={"age"}
          type="number"
          value={form.age}
          onChange={handleAgeChange}
        />
        <TextField
          variant="outlined"
          select
          label={"Пол"}
          name={"gender"}
          value={form.gender}
          onChange={handleGenderChange}
          SelectProps={{
            native: true,
          }}
        >
          {genderValue.map((option) => (
            <option key={option.value} value={option.value ?? ""}>
              {option.label}
            </option>
          ))}
        </TextField>
        <TextField
          variant="outlined"
          label={"Город"}
          name={"city"}
          value={form.city}
          onChange={handleCityChange}
        />
      </Stack>

      {error && (
        <Typography mb={2} color="tomato">
          {errorMessage}
        </Typography>
      )}

      <Button
        variant={"contained"}
        size="large"
        onClick={handleSaveSubjectInfo}
      >
        Сохранить
      </Button>
    </Box>
  );
};
