import {
  Box,
  Collapse,
  Container,
  Dialog,
  Divider,
  Fab,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Navbar } from "../components/Navbar";
import { useEffect, useState } from "react";
import { fetchSubject } from "../api/subjects/request";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../util/constants";
import { SubjectDto } from "../api/subjects/dto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModelPanelEditSubjectInfo } from "../components/ModelPanelEditSubjectInfo";
import { ModelPanelDeleteSubject } from "../components/ModelPanelDeleteSubject";
import { ModelPanelEditSpecialistComments } from "../components/ModelPanelEditSpecialistComments";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarElement,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  BarElement,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface SubjectPageParams {
  id: Record<string, string | undefined>;
}

export const SubjectPage = (): JSX.Element => {
  const [update, setUpdate] = useState<object>({});
  const [openEditSubject, setOpenEditSubject] = useState<boolean>(false);
  const [openDeleteSubject, setOpenDeleteSubject] = useState<boolean>(false);
  const [openEditResult, setOpenEditResult] = useState<boolean>(false);
  const handleOpenEditSubject = () => setOpenEditSubject(true);
  const handleOpenDeleteSubject = () => setOpenDeleteSubject(true);
  const handleOpenEditResult = () => setOpenEditResult(true);
  const handleCloseEditSubject = () => setOpenEditSubject(false);
  const handleCloseDeleteSubject = () => setOpenDeleteSubject(false);
  const handleCloseEditResult = () => setOpenEditResult(false);
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const [subject, setSubject] = useState<SubjectDto>();

  const [open, setOpen] = useState<boolean>(true);
  const labels1 = [
    "07.03.2024 7:13",
    "07.03.2024 7:15",
    "07.03.2024 8:54",
    "07.03.2024 8:54",
  ];

  const labels2 = [
    "07.03.2024 7:13",
    "07.03.2024 7:15",
    "07.03.2024 8:54",
    "07.03.2024 8:54",
  ];

  const data1 = {
    type: "line",
    labels: labels1,
    datasets: [
      {
        label: "Левый глаз",
        data: [1.0, 0.9, 1.0, 0.85],
        borderColor: "#F2522E",
        backgroundColor: "#F2522E",
      },
      {
        label: "Правый глаз",
        data: [0.6, 0.55, 0.6, 0.7],
        borderColor: "#3CAEEA",
        backgroundColor: "#3CAEEA",
      },
    ],
  };

  const data2 = {
    type: "line",
    labels: labels2,
    datasets: [
      {
        label: "Левый глаз",
        data: [0.5, 0.7, 0.6, 0.5],
        borderColor: "#F2522E",
        backgroundColor: "#F2522E",
      },
      {
        label: "Правый глаз",
        data: [0.4, 0.35, 0.5, 0.45],
        borderColor: "#3CAEEA",
        backgroundColor: "#3CAEEA",
      },
    ],
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Острота зрения",
      },
    },
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Контрастность зрения",
      },
    },
  };

  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState<number>(-1);
  const handleExpandClick = (id: number) => {
    if (expandedId === id) {
      setExpandedId(-1);
      setExpanded(false);
    } else {
      setExpandedId(id);
      setExpanded(true);
    }
  };

  useEffect(() => {
    fetchSubject(Number(id))
      .then((response) => {
        if (response.ok) return response.json();
        if (response.status === 401) navigate(routes.login);
        throw new Error("Request faild.");
      })
      .then((json) => setSubject(json))
      .catch((error) => console.error(error));
  }, [update]);

  useEffect(() => {
    console.log(subject);
  }, [subject]);

  return (
    <Container>
      <Navbar />

      <Stack my={2}>
        <Grid container={true} direction={"column"}>
          <Grid
            item={true}
            width={"100%"}
            container={true}
            direction={"row"}
            alignItems={"center"}
            mt={1}
            spacing={2}
          >
            <Grid item={true} xs={3}>
              <Typography fontSize={24} color={"#1D1929"} fontWeight={700}>
                Карточка испытуемого
              </Typography>
            </Grid>

            <Grid item xs={1} container justifyContent={"center"}>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={handleOpenEditSubject}
              >
                <EditIcon />
              </Fab>
              <Dialog open={openEditSubject} onClose={handleCloseEditSubject}>
                <ModelPanelEditSubjectInfo
                  subject={subject}
                  closeModal={handleCloseEditSubject}
                  idSubject={Number(id)}
                  update={setUpdate}
                />
              </Dialog>
            </Grid>

            <Grid item xs={1}>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={handleOpenDeleteSubject}
              >
                <DeleteIcon />
              </Fab>
              <Dialog
                open={openDeleteSubject}
                onClose={handleCloseDeleteSubject}
              >
                <ModelPanelDeleteSubject
                  idSubject={Number(id)}
                  closeModel={handleCloseDeleteSubject}
                />
              </Dialog>
            </Grid>
          </Grid>

          <Box
            sx={{
              boxShadow: "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
              borderRadius: "12px",
            }}
            padding={2}
            mt={2}
          >
            <Grid
              item={true}
              width={"100%"}
              container={true}
              direction={"row"}
              spacing={2}
            >
              <Grid
                item={true}
                xs={4}
                container={true}
                direction={"column"}
                justifyContent={"center"}
              >
                <Grid item={true}>
                  <Typography>{`Токен:`}</Typography>
                </Grid>

                <Grid item>
                  <Typography
                    style={{ fontSize: "45px" }}
                  >{`${subject?.token}`}</Typography>
                </Grid>
              </Grid>

              <Grid item={true} xs={4} container={true} direction={"column"}>
                <Grid item={true}>
                  <Typography>{`Имя:`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography style={{ fontSize: "24px" }}>{`${
                    subject?.name === null ? "-" : subject?.name
                  }`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography>{`Возраст:`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography style={{ fontSize: "24px" }}>{`${
                    subject?.age === null ? "-" : subject?.age
                  }`}</Typography>
                </Grid>
              </Grid>

              <Grid item={true} xs={4} container={true} direction={"column"}>
                <Grid item={true}>
                  <Typography>{`Город:`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography style={{ fontSize: "24px" }}>{`${
                    subject?.city === null ? "-" : subject?.city
                  }`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography>{`Пол:`}</Typography>
                </Grid>

                <Grid item={true}>
                  <Typography style={{ fontSize: "24px" }}>{`${
                    subject?.gender === 0
                      ? "Женский"
                      : subject?.gender === 1
                      ? "Мужской"
                      : "-"
                  }`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {subject?.results.length !== 0 && (
            <>
              <Grid
                container={true}
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                mt={1}
                spacing={2}
              >
                <Grid item={true} xs={3}>
                  <Typography fontSize={24} color={"#1D1929"} fontWeight={700}>
                    Результаты испытуемого
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={open}
                    exclusive
                    onChange={() => setOpen(!open)}
                    aria-label="Views"
                  >
                    <ToggleButton value={true}>
                      <Typography color="primary">Список</Typography>
                    </ToggleButton>
                    <ToggleButton value={false}>
                      <Typography color="primary">Дашборды</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              {open && (
                <Box
                  sx={{
                    boxShadow: "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "12px",
                  }}
                  padding={2}
                  mt={2}
                >
                  <Grid container={true} direction={"column"}>
                    {subject?.results.map((result) => {
                      const createDate = new Date(result.createdAt);
                      return (
                        <>
                          <Grid
                            item
                            onClick={() => handleExpandClick(result.id)}
                          >
                            <Typography
                              onClick={() => handleExpandClick(result.id)}
                            >{`Дата прохождения ${createDate.toLocaleDateString()} ${createDate.getHours()}:${createDate.getMinutes()}`}</Typography>
                          </Grid>

                          <Grid item>
                            <Collapse
                              in={expandedId === result.id ? expanded : false}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Grid
                                container
                                direction={"row"}
                                spacing={1}
                                m={0}
                                pr={2}
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction={"row"}
                                    columnSpacing={3}
                                  >
                                    <Grid item xs={4}>
                                      <Box
                                        sx={{
                                          boxShadow:
                                            "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                                          borderRadius: "12px",
                                        }}
                                        padding={2}
                                        mb={2}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Левый глаз
                                        </Typography>
                                        <Typography>{`Острота зрения: ${result.visualAcuityL}`}</Typography>
                                        <Typography>{`Перенапряжение: ${result.overstrainL}`}</Typography>
                                        <Typography>{`Контрастность: ${result.contrastL}`}</Typography>
                                        <Typography>{`Тест на астигматизм №1: ${result.astigmatism1L}`}</Typography>
                                        <Typography>{`Тест на астигматизм №2: ${result.astigmatism2L}`}</Typography>
                                        <Typography>{`Тест на астигматизм №3: ${result.astigmatism3L}`}</Typography>
                                        <Typography>{`Тест на поле зрения №1: ${result.retinalEx1L}`}</Typography>
                                        <Typography>{`Тест на поле зрения №2: ${result.retinalEx2L}`}</Typography>
                                        <Typography>{`Тест на поле зрения №3: ${result.retinalEx3L}`}</Typography>
                                        <Typography>{`Тест на поле зрения №4: ${result.retinalEx4L}`}</Typography>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Box
                                        sx={{
                                          boxShadow:
                                            "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                                          borderRadius: "12px",
                                        }}
                                        padding={2}
                                        mb={2}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Общий тест
                                        </Typography>
                                        <Typography>{`Цветовосприятие тест №1: ${result.colorPreception1}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №2: ${result.colorPreception2}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №3: ${result.colorPreception3}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №4: ${result.colorPreception4}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №5: ${result.colorPreception5}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №6: ${result.colorPreception6}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №7: ${result.colorPreception7}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №8: ${result.colorPreception8}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №9: ${result.colorPreception9}`}</Typography>
                                        <Typography>{`Цветовосприятие тест №10: ${result.colorPreception10}`}</Typography>
                                      </Box>
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Box
                                        sx={{
                                          boxShadow:
                                            "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                                          borderRadius: "12px",
                                        }}
                                        padding={2}
                                        mb={2}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Правый глаз
                                        </Typography>
                                        <Typography>{`Острота зрения: ${result.visualAcuityR}`}</Typography>
                                        <Typography>{`Перенапряжение: ${result.overstrainR}`}</Typography>
                                        <Typography>{`Контрастность: ${result.contrastR}`}</Typography>
                                        <Typography>{`Тест на астигматизм №1: ${result.astigmatism1R}`}</Typography>
                                        <Typography>{`Тест на астигматизм №2: ${result.astigmatism2R}`}</Typography>
                                        <Typography>{`Тест на астигматизм №3: ${result.astigmatism3R}`}</Typography>
                                        <Typography>{`Тест на поле зрения №1: ${result.retinalEx1R}`}</Typography>
                                        <Typography>{`Тест на поле зрения №2: ${result.retinalEx2R}`}</Typography>
                                        <Typography>{`Тест на поле зрения №3: ${result.retinalEx3R}`}</Typography>
                                        <Typography>{`Тест на поле зрения №4: ${result.retinalEx4R}`}</Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  container
                                  spacing={1}
                                  alignItems={"center"}
                                  mb={2}
                                >
                                  <Grid item xs={11}>
                                    <Box
                                      sx={{
                                        boxShadow:
                                          "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                                        borderRadius: "12px",
                                      }}
                                      padding={2}
                                    >
                                      <Typography>{`Оценка врача: ${
                                        result.specialistAssesment !== null
                                          ? result.specialistAssesment
                                          : "-"
                                      }`}</Typography>
                                      <Typography>{`Комментарий врача: ${
                                        result.comment !== null
                                          ? result.comment
                                          : "-"
                                      }`}</Typography>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={1}
                                    container
                                    justifyContent={"center"}
                                  >
                                    <Fab
                                      color="primary"
                                      aria-label="add"
                                      size="large"
                                      onClick={handleOpenEditResult}
                                    >
                                      <EditIcon />
                                    </Fab>
                                    <Dialog
                                      open={openEditResult}
                                      onClose={handleCloseEditResult}
                                    >
                                      <Box>
                                        <ModelPanelEditSpecialistComments
                                          closeModal={handleCloseEditResult}
                                        />
                                      </Box>
                                    </Dialog>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Collapse>
                          </Grid>
                          <Divider sx={{ marginTop: 1 }} />
                        </>
                      );
                    })}
                  </Grid>
                </Box>
              )}

              {!open && (
                <Box
                  sx={{
                    boxShadow: "0px 5px 10px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "12px",
                  }}
                  padding={2}
                  mt={2}
                >
                  <Grid
                    container
                    direction={"column"}
                    spacing={2}
                    paddingRight={2}
                  >
                    <Grid item xs={4} container direction={"row"} spacing={2}>
                      <Grid item xs={6}>
                        <Box
                          style={{
                            borderRadius: "14px",
                            backgroundColor: "white",
                          }}
                        >
                          <Line options={options1} data={data1} />
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          style={{
                            borderRadius: "14px",
                            backgroundColor: "white",
                          }}
                        >
                          <Line options={options2} data={data2} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Stack>
    </Container>
  );
};
