import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { StyledTable, StyledTableCell } from "./styles/StyledTable";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import React, {
  useEffect, useState
} from "react";
import { ResultDto, SubjectDto } from "../api/subjects/dto";
import { fetchAllSubjects } from "../api/subjects/request";
import { useNavigate } from "react-router-dom";
import { routes } from "../util/constants";
import CircleIcon from '@mui/icons-material/Circle';

// const FullNameTextStyle = {
//   fontSize: "18px",
//   color: "#1D1929",
//   letterSpacing: "0.02em",
// };

// const TokenStyleTExt = {
//   fontWight: "bold",
//   fontSize: "24px",
//   color: "#1D1929",
//   letterSpacing: "0.02em",
// };

interface SubjectsTableProps {
  update: object
}

export const SubjectsTable: React.FC<SubjectsTableProps> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);
  const [columnToSort, setColumnToSort] = useState("id");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  const invertDirection = {
    asc: "desc",
    desc: "asc",
  };

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(() => {
    const saved = localStorage.getItem("rowsPerPage");
    return parseInt(saved !== null && saved !== "NaN" ? saved : "10");
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function formatDate(date : any) {
    if (date === undefined) return '-';
    const tmpDate = new Date(date);
    return tmpDate.toLocaleDateString('ru');
  }

  // function resultAstigmatism(result: ResultDto, isLeft: boolean) {
  //   if (isLeft) {
  //     return result.astigmatism1L && result.astigmatism2L & result.astigmatism3L;
  //   } else {
  //     return result.astigmatism1R && result.astigmatism2R & result.astigmatism3R;
  //   }
  // }


  const handleSort = (columnName: string) => {
    console.log(columnName, columnToSort, sortDirection);
    setColumnToSort(columnName);
    setSortDirection(
      columnToSort === columnName
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ? (invertDirection as any)[sortDirection]
        : "asc"
    );
  };

  useEffect(() => {
    fetchAllSubjects()
      .then((response) => {
        if (response.ok) return response.json();
        if (response.status === 401) navigate(routes.login);
        throw new Error("Request faild.");
      })
      .then((json) => setSubjects(json))
      .catch((error) => console.error(error));
  }, [navigate, props.update]);

  return (
    <TableContainer>
      <StyledTable>
        <colgroup>
          <col style={{ width: "20" }} />
          <col style={{ width: "20" }} />
          <col style={{ width: "20" }} />
          <col style={{ width: "20" }} />
          <col style={{ width: "20" }} />
          {/* <col style={{ width: "8" }} />
          <col style={{ width: "8" }} />
          <col style={{ width: "8" }} />
          <col style={{ width: "9" }} />
          <col style={{ width: "9" }} />
          <col style={{ width: "9" }} />
          <col style={{ width: "9" }} /> */}
        </colgroup>

        <TableHead>
          <TableRow>
            {/* <StyledTableCell align="center"> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("id")}
              >
                <span>Токен</span>
                {columnToSort === "id" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div> */}
            {/* </StyledTableCell> */}

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("id")}
              >
                <span>Токен</span>
                {columnToSort === "id" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("name")}
              >
                <span>Имя</span>
                {columnToSort === "name" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("age")}
              >
                <span>Возраст</span>
                {columnToSort === "age" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("gender")}
              >
                <span>Пол</span>
                {columnToSort === "gender" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Город</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            {/* <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Кол-во тестов</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Дата последнего прохождения</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Острота зрения</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Контрастность зрения</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Перенапряжение</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell>

            <StyledTableCell align="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSort("city")}
              >
                <span>Астигматизм</span>
                {columnToSort === "city" ? (
                  sortDirection === "asc" ? (
                    <ArrowDropUpOutlinedIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                ) : null}
              </div>
            </StyledTableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {subjects
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((registration) => (
              <TableRow key={registration.token} onClick={() => navigate(routes.goToSubjectPage(registration.id))}>
                {/* <StyledTableCell align="center"><CircleIcon fontSize="inherit" /></StyledTableCell> */}

                <StyledTableCell align="center">{registration.token}</StyledTableCell>

                <StyledTableCell align="center">
                  {registration.name !== null ? registration.name : '-'}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Box fontWeight="bold">{registration.age !== null ? registration.age : '-'}</Box>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Box fontWeight="bold">{registration.gender === 0 ? "Женский" : registration.gender === 1 ? "Мужской" : "-"}</Box>
                </StyledTableCell>

                <StyledTableCell align="center">
                  {registration.city !== null ? registration.city : '-'}
                </StyledTableCell>

                {/* <StyledTableCell align="center">
                  {registration.results.length}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {formatDate(registration.results[0]?.createdAt)}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {`${registration.results[0]?.visualAcuityL ?? '-'} | ${registration.results[0]?.visualAcuityR ?? '-'}`}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {`${registration.results[0]?.contrastL ?? '-'} | ${registration.results[0]?.contrastR ?? '-'}`}
                </StyledTableCell>

                <StyledTableCell align="center">
                {`Есть подозрение | Нет`}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {`Есть подозрение | Нет`}
                </StyledTableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      {subjects !== undefined && <TablePagination
        sx={{ marginRight: 10 }}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={subjects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={'Записей на странице'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} из ${count !== -1 ? count : `more than ${to}`}`
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
    </TableContainer>
  );
};
