import { Button, Container, Fab, FormControl, Grid, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { Navbar } from "../components/Navbar";
import { SubjectsTable } from "../components/SubjectsTable";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { createNewSubject } from "../api/subjects/request";
import { Search } from "@mui/icons-material";

export const MainPage = (): JSX.Element => {
  const [update, setUpdate] = useState<object>({});
  function addNewSubject() {
    createNewSubject({
      name: undefined,
      age: undefined,
      city: undefined,
      gender: undefined,
      userId: 2
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Request faild.");
        }
      })
      .then((json) => {
        console.log(json);
        setUpdate({});
      })
      .catch((error) => console.error(error));
  }

  return (
    <Container>
      <Navbar />

      <Stack my={2}>
        <Grid item={true} xs={8}>
          <Grid
            container={true}
            width={"100%"}
            direction={"row"}
            alignItems={"center"}
            mt={1}
            spacing={2}
            // justifyContent={'space-between'}
          >
            <Grid item={true} xs={4}>
              <Typography fontSize={24} color={"#1D1929"} fontWeight={700}>
                Список испытуемых
              </Typography>
            </Grid>

            <Grid item xs={3}>
              {/* <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={addNewSubject}
              >
                <AddIcon />
              </Fab> */}
              <Button
                color="primary"
                variant="contained"
                aria-label="add"
                onClick={addNewSubject}
              >
                Добавить испытуемого
              </Button>
            </Grid>
{/* 
            <Grid item xs={5} >
              <FormControl fullWidth size="small">
                <OutlinedInput
                  startAdornment={
                    <Search />
                  }
                />
              </FormControl>
            </Grid> */}
          </Grid>
          <Grid container={true} width={"100%"} direction={"row"} spacing={2}>
            <Grid item={true} xs={12}>
              <SubjectsTable update={update} />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};

/*

import { Grid, Stack, Typography, Box, CircularProgress } from "@mui/material";
import { RegistrationDto } from "../../api/registrations/dto";
import React, { useState } from "react";
import { RegistrationsTable } from "./RegistrationsTable";
import {orderBy} from "lodash";

export interface IRegistrationsContentPage {
    registrations: RegistrationDto[],
    isFetching: boolean,
    setValue: Function
}

export const RegistrationsContentPage: React.FC<IRegistrationsContentPage> = ({ registrations, isFetching, setValue }) => {
    const [columnToSort, setColumnToSort] = useState('id');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>("desc");

    const invertDirection = {
        asc: "desc",
        desc: "asc"
    }

    const handleSort = (columnName: string) => {
        console.log(columnName, columnToSort, sortDirection);
        setColumnToSort(columnName);
        setSortDirection(columnToSort === columnName ? (invertDirection as any)[sortDirection] : 'asc')
    }
}
*/
